<template>
  <div class="auth-wrapper auth-v2 bg">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col lg="8" class="d-none d-lg-block position-relative overflow-hidden pa-0 auth-bg-left">
          <v-container fill-height grid-list-md text-xs-center>
            <v-layout row wrap align-center>
              <v-flex>
                <div class="left-text-container">
                  <div class="pa-16 pe-0 pt-8 pb-8 left-text-title">
                    The payment platform<br />you were waiting for.
                  </div>
                  <div class="pa-16 pe-0 pt-0 left-text-subtitle">
                    Our robust and flexible technology makes payments so seamless you don’t even need to think about
                    them
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-col>
        <v-col lg="4" class="align-center auth-bg-right pa-5 pb-0">
          <v-container>
            <div class="brand-logo">
              <img :src="appLogo" alt="Key2Pay"/>
            </div>
          </v-container>
          <v-container fill-height grid-list-md text-xs-center>
            <v-layout row wrap align-center>
              <v-flex>
                <v-card flat light v-if="isCheckingLink" :loading="isCheckingLink">
                  <v-card-subtitle class="align-center mt-5">
                    <p>Checking Link...</p>
                  </v-card-subtitle>
                </v-card>
                <v-card flat light v-show="!isCheckingLink && !isPasswordConfirmed" v-if="isValidLink">
                  <v-card-title>Reset Your Password</v-card-title>
                  <v-card-subtitle>
                    Choose a strong password that includes a combination of letters, numbers, and special characters
                  </v-card-subtitle>
                  <v-card-text>
                    <v-form
                      ref="resetPasswordForm"
                      v-model="isValid"
                      @submit.prevent="handleFormSubmit"
                    >
                      <v-text-field
                        v-model="password"
                        min-height="56"
                        outlined
                        label="New Password"
                        :error-messages="errorMessages.password"
                        placeholder="New Password"
                        :rules="[validators.required, validators.passwordValidator]"
                        hide-details="auto"
                        class="mb-5"
                        :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                        :type="isPasswordVisible ? 'text' : 'password'"
                        @click:append="isPasswordVisible = !isPasswordVisible"
                      ></v-text-field>

                      <v-text-field
                        v-model="confirmPassword"
                        min-height="56"
                        outlined
                        label="Confirm New Password"
                        :error-messages="errorMessages.password"
                        placeholder="Confirm New Password"
                        :rules="[validators.required, validators.passwordValidator]"
                        hide-details="auto"
                        class="mb-5"
                        :append-icon="isConfirmPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                        :type="isConfirmPasswordVisible ? 'text' : 'password'"
                        @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
                      ></v-text-field>

                      <div class="mb-5 pl-0">
                        <VueRecaptcha
                          ref="vueRecaptchaCtl"
                          :sitekey="siteKey"
                          :load-recaptcha-script="true"
                          @verify="handleReCaptchaSucess"
                          @error="handleReCaptchaError"
                          @expired="handleExpired"
                        ></VueRecaptcha>
                      </div>

                      <v-btn
                        block
                        min-height="56"
                        color="primary"
                        type="submit"
                        :disabled="!isValid || reCaptchaToken == null"
                        :loading="loading"
                      >
                        Submit
                      </v-btn>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-center align-center">
                    <!-- forget link -->
                    <router-link :to="{ name: 'auth-login' }" class="d-flex align-center text-sm forgot-password">
                      <span>Back to Login</span>
                    </router-link>
                  </v-card-actions>
                </v-card>
                <v-card flat light v-else-if="!isValid" v-show="!isCheckingLink">
                  <v-card-title class="justify-center align-center mb-5">
                    <v-icon size="70">{{ icons.mdiAlertCircleOutline }}</v-icon>
                  </v-card-title>
                  <v-card-subtitle class="align-center">
                    <p>Link invalid or expired. Please contact <a href="mailto:support@key2pay.online">support@key2pay.online</a>. Thanks!</p>
                  </v-card-subtitle>
                </v-card>
                <v-card flat light v-if="isPasswordConfirmed">
                  <v-card-title class="justify-center align-center mb-5">
                    <v-icon size="70">{{ icons.mdiCheckCircleOutline }}</v-icon>
                  </v-card-title>
                  <v-card-subtitle class="align-center">
                    Your password has been set successfully!
                  </v-card-subtitle>
                  <v-card-actions class="d-flex justify-center align-center">
                    <!-- forget link -->
                    <router-link :to="{ name: 'auth-login' }" class="d-flex align-center text-sm forgot-password">
                      <span>Back to Login</span>
                    </router-link>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-col>
      </v-row>

      <v-snackbar v-model="snackbar.isSnackbarVisible">
        <span v-html="snackbar.snackbarMessage"></span>
        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="snackbar.isSnackbarVisible = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline, mdiLockOutline, mdiAlertCircleOutline, mdiCheckCircleOutline } from '@mdi/js'
// eslint-disable-next-line object-curly-newline
import { required, passwordValidator, alphaValidator } from '@core/utils/validation'
import { ref, onMounted, computed } from '@vue/composition-api'
import axios from '@axios'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  components: {
    VueRecaptcha,
  },
  setup() {
    // Template Refs
    const resetPasswordForm = ref(null)
    const isValidLink = ref(false)
    const isCheckingLink = ref(true)
    const { route, router } = useRouter()
    const siteKey = computed(() => process.env.VUE_APP_RECAPTCHA_SITEKEY)
    const reCaptchaToken = ref(null)
    const vueRecaptchaCtl = ref(null)
    const isPasswordVisible = ref(false)
    const isConfirmPasswordVisible = ref(false)
    const isPasswordConfirmed = ref(false)
    const password = ref('')
    const confirmPassword = ref('')
    const errorMessages = ref([])
    const isValid = ref(false)
    const loading = ref(false)

    const snackbar = ref({
      isSnackbarVisible: false,
      snackBarColor: 'info',
      snackbarMessage: '',
      snackbarTimeOut: '3000',
    })

    const validate = () => {
      resetPasswordForm.value.validate()
    }

    const showSnackbarMessage = data => {
      snackbar.value.isSnackbarVisible = true
      snackbar.value.snackBarColor = data.type
      snackbar.value.snackbarMessage = data.message
    }

    onMounted(() => {
      isCheckingLink.value = true
      axios
        .get('/userAccess/authentication/password-reset-link/validate', { params: { token: route.value.query.token } })
        .then(() => {
          isValidLink.value = true
        })
        .catch(error => {
          errorMessages.value = error.response.data.error
          isValidLink.value = false
        })
        .finally(() => {
          isCheckingLink.value = false
        })
    })

    const handleReCaptchaError = () => {
      reCaptchaToken.value = null
    }

    const handleExpired = () => {
      reCaptchaToken.value = null
    }

    const handleReCaptchaSucess = response => {
      reCaptchaToken.value = response
    }

    const handleFormSubmit = () => {
      if (isValid.value) {
        loading.value = true
        axios
          .post('/userAccess/authentication/reset-password', {
            token: route.value.query.token,
            password: confirmPassword.value,
          })
          .then(() => {
            showSnackbarMessage({ type: 'info', message: 'Your password has been reseted' })
            password.value = ''
            confirmPassword.value = ''
            isPasswordConfirmed.value = true
          })
          .catch(error => {
            showSnackbarMessage({ message: error.response.data, type: 'error' })
          })
          .finally(() => {
            loading.value = false
          })
      } else {
        validate()
      }
    }

    return {
      isPasswordVisible,
      isConfirmPasswordVisible,
      isPasswordConfirmed,
      password,
      confirmPassword,
      errorMessages,
      isValidLink,
      isValid,
      isCheckingLink,
      snackbar,
      loading,
      resetPasswordForm,
      vueRecaptchaCtl,
      reCaptchaToken,
      siteKey,
      showSnackbarMessage,
      handleFormSubmit,
      handleReCaptchaError,
      handleReCaptchaSucess,
      handleExpired,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiLockOutline,
        mdiAlertCircleOutline,
        mdiCheckCircleOutline,
      },
      validators: {
        required,
        passwordValidator,
        alphaValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../../styles/auth.scss';
</style>
